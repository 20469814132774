import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { createStore, applyMiddleware, Middleware } from 'redux';
import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk';

import sitAppReducer from './store/reducers';
import initialState from './store/initial';
import { Provider } from 'react-redux';
import './i18n';
import { setThemeType, ThemeType } from './helpers/theme';
import { uiDisplayImpressum, uiPreferSubject, undoSelection } from './store/actions';
import i18n from 'i18next';

let applicationStore = createStore(sitAppReducer, initialState, applyMiddleware(thunkMiddleware));

let loggerMiddleware : Middleware|null = null;
if(process.env.NODE_ENV !== 'production') {
    loggerMiddleware = createLogger();
    applicationStore = createStore(sitAppReducer, initialState, applyMiddleware(thunkMiddleware, loggerMiddleware));
} 

declare let window: any;
const search = window.location.search;
const params = new URLSearchParams(search);
const app = params.get('app');
const gdpr = params.get('gdpr');
const lang = params.get('lang');
const subject = params.get('subject');

if(app) {
    setThemeType(ThemeType.App);
}
if(subject) {
    let dispatch = applicationStore.dispatch as any;
    dispatch(uiPreferSubject(parseInt(subject)));
}
if(gdpr) {
    let dispatch = applicationStore.dispatch as any;
    dispatch(uiDisplayImpressum(true));
}
if(lang === 'en') {
    i18n.changeLanguage('en');
}

window.onpopstate = function(event: any) {
    let currentIndex = (event.state === null) ? -1 : event.state.index;
    if(currentIndex <= window.historyIndex) {
        let dispatch = applicationStore.dispatch as any;
        dispatch(undoSelection());
    } 
    window.historyIndex = currentIndex;
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={applicationStore}>
            <App/>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
