let translation =  {
    translation: {
        title: 'Terminvereinbarung mit der Stabsstelle Internationales',
        user: {
            firstname: "Vorname",
            lastname: "Nachname",
            email: "E-Mail",
            invalidEmail: "Bitte geben Sie eine gültige E-Mail Adresse an.",
            phone: "Telefon",
            matriculationNumber: "Matrikelnummer",
            additionalInfo: "Nachricht ({{current}} von {{max}} Zeichen)",
            consent: "Ich habe die <1>Datenschutzerklärung</1> gelesen und bin mit der Verarbeitung meiner Daten einverstanden."
        },
        selection: {
            type: {
                title: "Art der Beratung",
                intro: "Bei diesem Thema können Sie zwischen einem Termin vor Ort (Präsenz) oder einem Online Termin (Online) wählen:",
                presence: "Präsenz",
                online: "Online",
                presenceOnly: "Dieser Termin findet vor Ort statt.",
                onlineOnly: "Dieser Termin findet online statt.",
                outro: "Sie bekommen alle notwendigen Informationen um an der Beratung teilzunehmen (Ort, Link zum Online Meeting, etc.) via E-Mail."
            },
            subject: {
                title: "Thema",
                none: "Es konnten keine Themen gefunden werden!"
            },
            dateslots: {
                none: "Es konnte kein {{chosen}}termin innerhalb der nächsten {{days}} Tage für Ihr gewähltes Thema gefunden werden. Wählen Sie falls möglich einen {{notChosen}}termin oder versuchen Sie es später noch einmal.",
                presence: "Präsenz",
                online: "Online",
                change: "Art der Beratung ändern"
            },
            date: {
                info: "Sie können einen Termin innerhalb der nächsten {{days}} Tage buchen."
            },
            time: {
                info: 'Alle Zeitangaben nutzen die mitteleuropäische Zeit (MEZ).'
            },
            placeholder: {
                topic: "Wählen Sie ein Thema aus.",
                date: "Wählen Sie ein Datum aus.",
                time: "Wählen Sie eine Zeit aus.",
                user: "Geben Sie Ihre Daten ein.",
                consultationType: "Wählen Sie die gewünschte Beratungsart."
            },
            required: "Angabe notwendig"
        },
        contact: {
            title: "KONTAKT",
            subtitle: "Stabsstelle Internationales",
            street: "Goethestraße 3-5",
            location: "04109 Leipzig"
        },
        overview: {
            title: "MEINE AUSWAHL",
            book: "Termin Buchen"
        },
        add: {
            app: {            
                title: "Nutzen Sie die App entdecker – Abenteuer Ausland",
                subTitle: "für die Planung und Durchführung Ihres Auslandsaufenthalts",
                url: "https://www.uni-leipzig.de/international/studium-und-praktikum-im-ausland/entdecker-app/"
            },
            stories: {            
                title: "Lesen Sie in den Entdeckerstorys",
                subTitle: "von den Auslandserfahrungen anderer",
                url: "https://www.uni-leipzig.de/entdeckerstorys/?ADMCMD_previewWS=1"
            }
        },
        commit: {
            almost: "Fast geschafft",
            error: "Es gab leider ein Problem:",
            committing: "Ihre Daten werden übertragen...",
            info: "Sie erhalten in den nächsten Minuten eine E-Mail zur Bestätigung dieses Termins. Sie müssen den Termin innerhalb der nächsten 30 Minuten bestätigen.",
            tryAgain: "Noch einmal versuchen",
            backToReferrer: "Zurück zur Uni-Leipzig"
        },
        errors: {
            network: "Es konnte keine Verbindung zum Buchungssystem hergestellt werden.",
            system: "Es gab einen Fehler im Buchungssystem. Bitte versuchen Sie es noch einmal.",
            noslot: "Es konnte kein Termin zu dieser Zeit gefunden werden.",
            alreadyBooked: "Unter dieser E-Mail Adresse existiert bereits ein Termin für dieses Thema!",
            alreadyOver: "Dieser Termin ist nicht mehr verfügbar.",
            subject: "Ihr gewähltes Thema konnte nicht gefunden werden.",
            token: "Die Buchung hat leider zu lange gedauert. Bitte laden Sie die Seite neu und führen Sie die Buchung noch einmal durch."
        },
        impressum: {
            show: 'Impressum und Datenschutz',
            back: 'Zurück zur Terminbuchung'
        },
        captcha: {
            info: '',
            phrase: ''
        }
    }
};

export default translation;